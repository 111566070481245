/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export enum AcceptanceReportAttendeeRole {
  Client = 'CLIENT',
  HeinrichSchmid = 'HEINRICH_SCHMID',
  Other = 'OTHER'
}

export type AcceptanceReportAttendeeSaveInput = {
  fullName: Scalars['String']['input'];
  position?: InputMaybe<Scalars['String']['input']>;
  role: AcceptanceReportAttendeeRole;
};

export type AcceptanceReportDefectCorrectionSaveInput = {
  dueDate: Scalars['Date']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptanceReportDefectMetaSaveInput = {
  description: Scalars['String']['input'];
  imageId?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptanceReportDefectSaveInput = {
  agreed: Scalars['Boolean']['input'];
  correction?: InputMaybe<AcceptanceReportDefectCorrectionSaveInput>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  metaData?: InputMaybe<Array<AcceptanceReportDefectMetaSaveInput>>;
  note?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<AcceptanceReportDefectWarrantySaveInput>;
};

export type AcceptanceReportDefectWarrantySaveInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type AcceptanceReportDescriptionSaveInput = {
  reference?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptanceReportImageInput = {
  imageId: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptanceReportSaveInput = {
  acceptance: Scalars['Boolean']['input'];
  acceptanceDescription?: InputMaybe<AcceptanceReportDescriptionSaveInput>;
  alias?: InputMaybe<Scalars['String']['input']>;
  attachImagesToPdf: Scalars['Boolean']['input'];
  attendees: Array<AcceptanceReportAttendeeSaveInput>;
  clientName: Scalars['String']['input'];
  contractor: Scalars['String']['input'];
  date: Scalars['Date']['input'];
  defect?: InputMaybe<AcceptanceReportDefectSaveInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  draftProjectNumber?: InputMaybe<Scalars['String']['input']>;
  hasDefect: Scalars['Boolean']['input'];
  images?: InputMaybe<Array<AcceptanceReportImageInput>>;
  professions?: InputMaybe<Array<Scalars['String']['input']>>;
  projectName: Scalars['String']['input'];
};

export enum AcceptanceReportSignatureType {
  Customer = 'CUSTOMER',
  HeinrichSchmid = 'HEINRICH_SCHMID'
}

export enum AcceptanceReportStatus {
  Archived = 'ARCHIVED',
  ManuallySigned = 'MANUALLY_SIGNED',
  /** @deprecated Since this status has been merged with the OPEN status, it will be removed on 27.06.2025 */
  NotAgreed = 'NOT_AGREED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED',
  /** @deprecated Since this status is no longer used, it will be removed on 27.06.2025 */
  SignedWithDefects = 'SIGNED_WITH_DEFECTS'
}

export type AddBulkOperationToFolderInput = {
  /** ID of the folder. */
  folder: Scalars['ID']['input'];
  /** IDs of the catalogue operations */
  operations: Array<Scalars['ID']['input']>;
};

export type AddOperationToFolderInput = {
  /** Is this operation's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the category. */
  category?: InputMaybe<Scalars['ID']['input']>;
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Id of the category. */
  folder: Scalars['ID']['input'];
  /** Measurements of operation */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Note for the operation */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Id of the operation. */
  operation?: InputMaybe<Scalars['ID']['input']>;
  /** Is this operation optional */
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  /** Operation payment input. Please send totalPrice flat is true, otherwise please send discount/surcharge value and type. If you do not have any price adjustment please do not send this input at all. */
  payment?: InputMaybe<OperationPaymentInput>;
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export enum ArchiveDriveBulkJobMetaFailureReason {
  InvalidCredentials = 'INVALID_CREDENTIALS',
  ServerError = 'SERVER_ERROR'
}

export type AssignDocumentToProjectInput = {
  id: Scalars['ID']['input'];
  project: Scalars['ID']['input'];
  type: DocumentType;
};

export type AssignOfferToProjectInput = {
  /** Id of the offer. */
  offer: Scalars['ID']['input'];
  /** Project ID that offer will be assigned to. */
  to: Scalars['ID']['input'];
};

export type CloneFolderInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the source. */
  from: Scalars['ID']['input'];
  /** Measurements of clone folder */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the clone folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type CloneOperationInput = {
  /** Is this operation's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Id of the source operation. */
  from: Scalars['ID']['input'];
  /** Measurements of clone operation */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Note for the clone operation */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Is this operation optional */
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  /** Operation payment input. Please send totalPrice flat is true, otherwise please send discount/surcharge value and type. If you do not have any price adjustment please do not send this input at all. */
  payment?: InputMaybe<OperationPaymentInput>;
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export type ConcernArchiveInput = {
  /** Id of the concern. */
  documentId: Scalars['ID']['input'];
  /** SAP password */
  sapPassword: Scalars['String']['input'];
  /** SAP username */
  sapUsername: Scalars['String']['input'];
};

export type ConcernDuplicateInput = {
  documentId: Scalars['ID']['input'];
};

export type ConcernImageInput = {
  /** Id of the image. */
  imageId: Scalars['ID']['input'];
  /** Label for the image. */
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ConcernPdfEmailSendInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of concern */
  documentId: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type ConcernSaveInput = {
  /** Clientname of concern */
  clientName: Scalars['String']['input'];
  /** Contractor of concern */
  contractor?: InputMaybe<ContractorSaveInput>;
  /** Date of concern */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Document id for the concern */
  documentId: Scalars['ID']['input'];
  /** End date of concern */
  endDate: Scalars['Date']['input'];
  /** Execution of concern */
  execution?: InputMaybe<Scalars['String']['input']>;
  /** Images for the obstruction */
  images: Array<ConcernImageInput>;
  /** Professions of concern */
  professions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Quality of concern */
  quality?: InputMaybe<Array<QualitySaveInput>>;
};

export type ConcernSignInput = {
  /** ID of the concern */
  documentId: Scalars['ID']['input'];
  /** Full name of the signatory */
  fullName: Scalars['String']['input'];
  /** Image of the signature */
  image: Scalars['Upload']['input'];
};

/** Concern status enum */
export enum ConcernStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export type ConstructionNoteCanvasUpdateInput = {
  /** Document ID of the construction note. */
  documentId: Scalars['ID']['input'];
};

export type ConstructionNoteCreateInput = {
  /** Document ID of the construction note. */
  documentId: Scalars['ID']['input'];
  /** Construction note name. */
  name: Scalars['String']['input'];
};

/** Construction note status enum */
export enum ConstructionNoteStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN',
  Sent = 'SENT'
}

/** Construction note type enum */
export enum ConstructionNoteType {
  FreeDrawing = 'FREE_DRAWING',
  Image = 'IMAGE',
  Pdf = 'PDF'
}

export type ConstructionNoteUpdateInput = {
  /** Document ID of the construction note. */
  documentId: Scalars['ID']['input'];
  /** Construction note name. */
  name: Scalars['String']['input'];
};

export type ContactPersonAddInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<Scalars['String']['input']>>;
  projectId: Scalars['ID']['input'];
};

export type ContactPersonRemoveInput = {
  id: Scalars['ID']['input'];
};

export type ContactPersonUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ContractorSaveInput = {
  /** Description of contractor */
  description: Scalars['String']['input'];
  /** Name of contractor */
  name: Scalars['String']['input'];
};

export type CreateFolderForFrameworkInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the catalogue category. */
  category: Scalars['ID']['input'];
  /** Measurements of folder */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Id of the offer. */
  offer: Scalars['ID']['input'];
  /** Id of the parent folder. */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type CreateFolderForFreeFormInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Measurements of folder */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Id of the offer. */
  offer: Scalars['ID']['input'];
  /** Id of the parent folder. */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type CreateMeasurementInput = {
  meta: Array<Scalars['String']['input']>;
  multiplier?: InputMaybe<Scalars['Float']['input']>;
  /** Offer name. */
  name: Scalars['String']['input'];
  /** Is subtraction enabled */
  subtraction: Scalars['Boolean']['input'];
  /** Total measurement */
  total: Scalars['Float']['input'];
  /** Total measurement meta for the operation. */
  totalMeta: Scalars['String']['input'];
};

export type CreateOperationInput = {
  /** Id of the category. */
  category: Scalars['ID']['input'];
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export type CustomFileEmailSendInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the custom file. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export enum CustomFileSource {
  Baumappe = 'BAUMAPPE',
  Hspass = 'HSPASS',
  Sap = 'SAP'
}

export type CustomFileUploadInput = {
  documentId: Scalars['ID']['input'];
  file: Scalars['Upload']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type CustomPdfFileFormFieldValuesUpdateInput = {
  documentId: Scalars['ID']['input'];
  formFieldValues: Array<PspdfKitFormField>;
};

export enum DigitalSalaryHistoryAction {
  CommercialApprove = 'COMMERCIAL_APPROVE',
  SapTransmission = 'SAP_TRANSMISSION',
  TechnicalApprove = 'TECHNICAL_APPROVE'
}

export enum DigitalSalaryRole {
  CommercialApprover = 'COMMERCIAL_APPROVER',
  SapTransmission = 'SAP_TRANSMISSION',
  TechnicalApprover = 'TECHNICAL_APPROVER'
}

/** Discount or surcharge value enum */
export enum DiscountOrSurchargeValueType {
  Amount = 'AMOUNT',
  Percentage = 'PERCENTAGE'
}

export enum DocumentManipulator {
  Pspdfkit = 'PSPDFKIT'
}

export enum DocumentType {
  AcceptanceReport = 'ACCEPTANCE_REPORT',
  Concern = 'CONCERN',
  ConstructionNote = 'CONSTRUCTION_NOTE',
  CustomFile = 'CUSTOM_FILE',
  FinancialDocument = 'FINANCIAL_DOCUMENT',
  Obstruction = 'OBSTRUCTION',
  Offer = 'OFFER',
  Overtime = 'OVERTIME',
  Qsst = 'QSST',
  RiskAssessment = 'RISK_ASSESSMENT',
  SivBillingAttachment = 'SIV_BILLING_ATTACHMENT'
}

export type DriveBulkJobCancelInput = {
  id: Scalars['ID']['input'];
};

export type DriveBulkJobRemoveInput = {
  id: Scalars['ID']['input'];
};

export enum DriveBulkJobStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Queued = 'QUEUED'
}

export type DriveDocumentArchiveInput = {
  id: Scalars['ID']['input'];
  sapPassword: Scalars['String']['input'];
  sapUsername: Scalars['String']['input'];
};

export type DriveDocumentBulkArchiveInput = {
  documentIds: Array<Scalars['ID']['input']>;
  sapPassword: Scalars['String']['input'];
  sapUsername: Scalars['String']['input'];
};

export type DriveDocumentBulkDownloadInput = {
  documentIds: Array<Scalars['ID']['input']>;
};

export type DriveDocumentBulkSendEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  documentIds: Array<Scalars['ID']['input']>;
  subject: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};

export type DriveDocumentEmailSendInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};

export type DriveDocumentGenerateIdInput = {
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
};

export type DriveDocumentLastUsedUpdateInput = {
  id: Scalars['ID']['input'];
};

export type DriveDocumentNoteSaveInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

export type DriveDocumentRemoveInput = {
  id: Scalars['ID']['input'];
};

export type DriveDocumentRenameInput = {
  id: Scalars['ID']['input'];
  newName: Scalars['String']['input'];
};

export enum DriveDocumentStatus {
  Archived = 'ARCHIVED',
  ManuallySigned = 'MANUALLY_SIGNED',
  /** @deprecated Since this status has been merged with the OPEN status, it will be removed on 27.06.2025 */
  NotAgreed = 'NOT_AGREED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED',
  /** @deprecated Since this status is no longer used, it will be removed on 27.06.2025 */
  SignedWithDefects = 'SIGNED_WITH_DEFECTS',
  Unknown = 'UNKNOWN'
}

export type DriveDocumentsExternalLinkEmailSendInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  ids: Array<Scalars['ID']['input']>;
  subject: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};

export type DriveDocumentsMoveInput = {
  ids: Array<Scalars['ID']['input']>;
  targetFolderId: Scalars['ID']['input'];
};

export type DriveDocumentsRemoveInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DriveEntrySortingInput = {
  direction: DriveSortingDirection;
  type: DriveEntrySortingType;
};

export enum DriveEntrySortingType {
  Alphabetical = 'ALPHABETICAL',
  LastChangedDate = 'LAST_CHANGED_DATE',
  LastUsedDate = 'LAST_USED_DATE',
  Status = 'STATUS'
}

export type DriveFolderCreateAndMoveDocumentsInput = {
  documentIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  parentId: Scalars['ID']['input'];
};

export type DriveFolderCreateInput = {
  name: Scalars['String']['input'];
  parentId: Scalars['ID']['input'];
};

export type DriveFolderLastUsedUpdateInput = {
  id: Scalars['ID']['input'];
};

export type DriveFolderNoteSaveInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  folderId: Scalars['ID']['input'];
};

export type DriveFolderRemoveInput = {
  fallbackFolderId?: InputMaybe<Scalars['ID']['input']>;
  folderId: Scalars['ID']['input'];
};

export type DriveFolderSortingInput = {
  direction: DriveSortingDirection;
  type: DriveFolderSortingType;
};

export enum DriveFolderSortingType {
  Alphabetical = 'ALPHABETICAL',
  LastUsedDate = 'LAST_USED_DATE',
  Standard = 'STANDARD'
}

export enum DriveFolderSubtype {
  /** Plaene */
  Blueprint = 'BLUEPRINT',
  /** Abnahmen */
  ConstructionSideAcceptance = 'CONSTRUCTION_SIDE_ACCEPTANCE',
  /** Baustellenbeschrieb */
  ConstructionSideDescription = 'CONSTRUCTION_SIDE_DESCRIPTION',
  /** Baustellendokumentationr */
  ConstructionSideDocumentation = 'CONSTRUCTION_SIDE_DOCUMENTATION',
  /** Bauvertraege */
  Contract = 'CONTRACT',
  /** Korrespondenz */
  Correspondence = 'CORRESPONDENCE',
  /** SUB / Leiher */
  FinancialBorrowers = 'FINANCIAL_BORROWERS',
  /** Rechnung / AZ */
  FinancialInvoices = 'FINANCIAL_INVOICES',
  /** Lieferanten */
  FinancialSuppliers = 'FINANCIAL_SUPPLIERS',
  /** Buergschaften */
  Guarantee = 'GUARANTEE',
  /** Aufmaß */
  Measurement = 'MEASUREMENT',
  /** Angebote */
  Offer = 'OFFER',
  /** Rapporte */
  Overtime = 'OVERTIME',
  /** Nachunternehmer */
  Subcontractor = 'SUBCONTRACTOR',
  /** Nachtraege */
  SupplementaryOffer = 'SUPPLEMENTARY_OFFER'
}

export enum DriveFolderType {
  Custom = 'CUSTOM',
  Root = 'ROOT',
  SystemBauarchiv = 'SYSTEM_BAUARCHIV',
  SystemBaumappe = 'SYSTEM_BAUMAPPE'
}

export type DriveFolderUpdateInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type DriveSorting = {
  direction: DriveSortingDirection;
  field: DriveSortingField;
};

/** The sorting direction */
export enum DriveSortingDirection {
  /** Lowest is the first */
  Asc = 'ASC',
  /** Greatest is the first */
  Desc = 'DESC'
}

/** The sorting field for Drive Document */
export enum DriveSortingField {
  AddedBy = 'ADDED_BY',
  Custom = 'CUSTOM',
  Name = 'NAME',
  No = 'NO',
  Status = 'STATUS',
  UpdatedAt = 'UPDATED_AT'
}

export type DriveViewPreferencesInput = {
  bauarchiv: Scalars['Boolean']['input'];
  baumappe: Scalars['Boolean']['input'];
};

export type DuplicateAcceptanceReportInput = {
  acceptanceReportId: Scalars['String']['input'];
};

export type DuplicateConstructionNoteInput = {
  /** ID of the construction note. */
  constructionNote: Scalars['String']['input'];
};

export type DuplicateOvertimeInput = {
  overtime: Scalars['String']['input'];
};

export type DuplicateRiskAssessmentInput = {
  riskAssessment: Scalars['String']['input'];
};

export enum EmailSendDriveBulkJobMetaFailureReason {
  MaxFileSizeExceed = 'MAX_FILE_SIZE_EXCEED',
  ServerError = 'SERVER_ERROR'
}

export type EmployeeSorting = {
  direction: EmployeeSortingDirection;
  type: EmployeeSortingType;
};

export enum EmployeeSortingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum EmployeeSortingType {
  Name = 'NAME',
  ProfitCenterId = 'PROFIT_CENTER_ID',
  SapId = 'SAP_ID'
}

export enum Event {
  AcceptanceReportArchived = 'ACCEPTANCE_REPORT_ARCHIVED',
  AcceptanceReportCreated = 'ACCEPTANCE_REPORT_CREATED',
  AcceptanceReportDeleted = 'ACCEPTANCE_REPORT_DELETED',
  AcceptanceReportEmailSent = 'ACCEPTANCE_REPORT_EMAIL_SENT',
  AcceptanceReportExternalEmailSent = 'ACCEPTANCE_REPORT_EXTERNAL_EMAIL_SENT',
  AcceptanceReportExternalLinkExpired = 'ACCEPTANCE_REPORT_EXTERNAL_LINK_EXPIRED',
  AcceptanceReportManuallySigned = 'ACCEPTANCE_REPORT_MANUALLY_SIGNED',
  AcceptanceReportNotSigned = 'ACCEPTANCE_REPORT_NOT_SIGNED',
  AcceptanceReportSignedByCustomer = 'ACCEPTANCE_REPORT_SIGNED_BY_CUSTOMER',
  AcceptanceReportUpdated = 'ACCEPTANCE_REPORT_UPDATED',
  ConcernArchived = 'CONCERN_ARCHIVED',
  ConcernCreated = 'CONCERN_CREATED',
  ConcernDeleted = 'CONCERN_DELETED',
  ConcernEmailSent = 'CONCERN_EMAIL_SENT',
  ConcernSigned = 'CONCERN_SIGNED',
  ConcernUpdated = 'CONCERN_UPDATED',
  ConstructionNoteArchived = 'CONSTRUCTION_NOTE_ARCHIVED',
  ConstructionNoteCreated = 'CONSTRUCTION_NOTE_CREATED',
  ConstructionNoteDeleted = 'CONSTRUCTION_NOTE_DELETED',
  ConstructionNoteEmailSent = 'CONSTRUCTION_NOTE_EMAIL_SENT',
  ConstructionNoteUpdated = 'CONSTRUCTION_NOTE_UPDATED',
  CustomFileArchived = 'CUSTOM_FILE_ARCHIVED',
  CustomFileCreated = 'CUSTOM_FILE_CREATED',
  CustomFileDeleted = 'CUSTOM_FILE_DELETED',
  CustomFileDuplicatedForManipulation = 'CUSTOM_FILE_DUPLICATED_FOR_MANIPULATION',
  CustomFileEmailSent = 'CUSTOM_FILE_EMAIL_SENT',
  CustomFileManipulated = 'CUSTOM_FILE_MANIPULATED',
  CustomFileUpdated = 'CUSTOM_FILE_UPDATED',
  DocumentMoved = 'DOCUMENT_MOVED',
  DriveFolderCreated = 'DRIVE_FOLDER_CREATED',
  DriveFolderDeleted = 'DRIVE_FOLDER_DELETED',
  DriveFolderUpdated = 'DRIVE_FOLDER_UPDATED',
  FinancialDocumentEmailSent = 'FINANCIAL_DOCUMENT_EMAIL_SENT',
  FinancialDocumentImported = 'FINANCIAL_DOCUMENT_IMPORTED',
  ObstructionArchived = 'OBSTRUCTION_ARCHIVED',
  ObstructionCreated = 'OBSTRUCTION_CREATED',
  ObstructionDeleted = 'OBSTRUCTION_DELETED',
  ObstructionEmailSent = 'OBSTRUCTION_EMAIL_SENT',
  ObstructionSigned = 'OBSTRUCTION_SIGNED',
  ObstructionUpdated = 'OBSTRUCTION_UPDATED',
  OfferCreated = 'OFFER_CREATED',
  OfferDeleted = 'OFFER_DELETED',
  OfferEmailSent = 'OFFER_EMAIL_SENT',
  OvertimeArchived = 'OVERTIME_ARCHIVED',
  OvertimeCreated = 'OVERTIME_CREATED',
  OvertimeDeleted = 'OVERTIME_DELETED',
  OvertimeEmailSent = 'OVERTIME_EMAIL_SENT',
  OvertimeExternalEmailSent = 'OVERTIME_EXTERNAL_EMAIL_SENT',
  OvertimeExternalLinkExpired = 'OVERTIME_EXTERNAL_LINK_EXPIRED',
  OvertimeManuallySigned = 'OVERTIME_MANUALLY_SIGNED',
  OvertimeNotSigned = 'OVERTIME_NOT_SIGNED',
  OvertimeRenamed = 'OVERTIME_RENAMED',
  OvertimeSignedByCustomer = 'OVERTIME_SIGNED_BY_CUSTOMER',
  OvertimeUpdated = 'OVERTIME_UPDATED',
  ProjectClientInformationChanged = 'PROJECT_CLIENT_INFORMATION_CHANGED',
  ProjectConstructionInformationChanged = 'PROJECT_CONSTRUCTION_INFORMATION_CHANGED',
  ProjectExternalStatusChanged = 'PROJECT_EXTERNAL_STATUS_CHANGED',
  ProjectImported = 'PROJECT_IMPORTED',
  ProjectNumberChanged = 'PROJECT_NUMBER_CHANGED',
  ProjectProfitCenterChanged = 'PROJECT_PROFIT_CENTER_CHANGED',
  ProjectStatusUpdated = 'PROJECT_STATUS_UPDATED',
  ProjectSynced = 'PROJECT_SYNCED',
  QsstArchived = 'QSST_ARCHIVED',
  QsstAssignedToProject = 'QSST_ASSIGNED_TO_PROJECT',
  QsstCreated = 'QSST_CREATED',
  QsstDeleted = 'QSST_DELETED',
  QsstDownloaded = 'QSST_DOWNLOADED',
  QsstEmailSent = 'QSST_EMAIL_SENT',
  QsstEmailSentWithAttachment = 'QSST_EMAIL_SENT_WITH_ATTACHMENT',
  QsstExternalLinkExpired = 'QSST_EXTERNAL_LINK_EXPIRED',
  QsstNotSigned = 'QSST_NOT_SIGNED',
  QsstSigned = 'QSST_SIGNED',
  QsstSignedOnSide = 'QSST_SIGNED_ON_SIDE',
  QsstUpdated = 'QSST_UPDATED',
  QsstUploaded = 'QSST_UPLOADED',
  QsstUploadedDeleted = 'QSST_UPLOADED_DELETED',
  RiskAssessmentArchived = 'RISK_ASSESSMENT_ARCHIVED',
  RiskAssessmentCreated = 'RISK_ASSESSMENT_CREATED',
  RiskAssessmentDeleted = 'RISK_ASSESSMENT_DELETED',
  RiskAssessmentEmailSent = 'RISK_ASSESSMENT_EMAIL_SENT',
  RiskAssessmentSignedByCreator = 'RISK_ASSESSMENT_SIGNED_BY_CREATOR',
  RiskAssessmentSignedByEmployee = 'RISK_ASSESSMENT_SIGNED_BY_EMPLOYEE',
  RiskAssessmentUpdated = 'RISK_ASSESSMENT_UPDATED'
}

export type ExportOfferInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the offer. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export enum ExternalOfferSource {
  Itworun = 'ITWORUN',
  Vendoc = 'VENDOC'
}

export type ExtraPriceInput = {
  /** Name of the extra price. */
  name: Scalars['String']['input'];
  /** Type of the extra price. */
  type: ExtraPriceType;
  /** Extra price value based on it's type. */
  value: Scalars['Float']['input'];
};

/** Extra price type enum */
export enum ExtraPriceType {
  Amount = 'AMOUNT',
  Percentage = 'PERCENTAGE'
}

export enum FinancialFolderSubType {
  FinancialBorrowers = 'FINANCIAL_BORROWERS',
  FinancialInvoices = 'FINANCIAL_INVOICES',
  FinancialSuppliers = 'FINANCIAL_SUPPLIERS'
}

export type FollowProjectToggleInput = {
  projectId: Scalars['ID']['input'];
};

export type GenerateOfferPdfInput = {
  /** Id of the offer. */
  offer: Scalars['ID']['input'];
};

export type GetPspdfkitAuthTokenInput = {
  documentId: Scalars['ID']['input'];
  requestEdit?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ImportProjectInput = {
  hsId: Scalars['String']['input'];
};

export enum InChargeRole {
  FirstAider = 'FIRST_AIDER',
  OperationManager = 'OPERATION_MANAGER',
  RegionSecurityManager = 'REGION_SECURITY_MANAGER',
  SecurityManager = 'SECURITY_MANAGER',
  SideManager = 'SIDE_MANAGER'
}

export enum LanguageCode {
  De = 'DE',
  En = 'EN',
  Fr = 'FR'
}

export type LogResetUnreadCountInput = {
  projectId: Scalars['String']['input'];
};

export enum NumerableDocumentType {
  Overtime = 'OVERTIME'
}

export type ObstructionArchiveInput = {
  /** Id of the obstruction. */
  documentId: Scalars['ID']['input'];
  /** SAP password */
  sapPassword: Scalars['String']['input'];
  /** SAP username */
  sapUsername: Scalars['String']['input'];
};

export type ObstructionDuplicateInput = {
  /** Id of the obstruction */
  documentId: Scalars['ID']['input'];
};

export type ObstructionEmailSendInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the obstruction. */
  documentId: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type ObstructionFactSaveInput = {
  /** Description of the obstruction fact */
  description: Scalars['String']['input'];
  /** Title of the obstruction fact */
  title: Scalars['String']['input'];
};

export type ObstructionImageInput = {
  /** Id of the image. */
  imageId: Scalars['ID']['input'];
  /** Label for the image. */
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ObstructionSaveInput = {
  /** Name of the client. */
  clientName: Scalars['String']['input'];
  /** Date of the obstruction. */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Document ID of the obstruction. */
  documentId: Scalars['ID']['input'];
  /** Facts of the obstruction */
  facts: Array<ObstructionFactSaveInput>;
  /** Images for the obstruction */
  images: Array<ObstructionImageInput>;
  /** Contractor of the obstruction. */
  professions: Array<Scalars['String']['input']>;
};

export type ObstructionSignInput = {
  /** ID of the obstruction */
  documentId: Scalars['ID']['input'];
  /** Full name of the signatory */
  fullName: Scalars['String']['input'];
  /** Image of the signature */
  image: Scalars['Upload']['input'];
};

/** Obstruction status enum */
export enum ObstructionStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export type OfferDraftClientMetaDataInput = {
  /** Address of the draft client. */
  address?: InputMaybe<Scalars['String']['input']>;
  /** City of the draft client. */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Name of the draft client. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Zip code of the draft client. */
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type OfferFrameworkSaveInput = {
  /** Catalogue ID of the offer. */
  catalogueId: Scalars['String']['input'];
  /** Document ID of the offer. */
  documentId: Scalars['ID']['input'];
  /** Meta data for offer. */
  meta?: InputMaybe<OfferMetaInput>;
  mode: OfferMode;
  /** Offer name. */
  name: Scalars['String']['input'];
};

export type OfferFreeFormSaveInput = {
  /** Document ID of the offer. */
  documentId: Scalars['ID']['input'];
  /** Meta data for offer. */
  meta?: InputMaybe<OfferMetaInput>;
  mode: OfferMode;
  /** Offer name. */
  name: Scalars['String']['input'];
};

export type OfferMetaInput = {
  /** Draft client meta data. */
  draftClient?: InputMaybe<OfferDraftClientMetaDataInput>;
  /** Draft construction site meta data. */
  draftConstructionSite?: InputMaybe<OfferDraftClientMetaDataInput>;
};

/** Offer mode enum */
export enum OfferMode {
  AliasNumber = 'ALIAS_NUMBER',
  SystemNumber = 'SYSTEM_NUMBER'
}

/** Offer Sortable type enum */
export enum OfferSortableType {
  Folder = 'FOLDER',
  FolderOperation = 'FOLDER_OPERATION'
}

/** Offer status enum */
export enum OfferStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN'
}

/** Offer type enum */
export enum OfferType {
  Framework = 'FRAMEWORK',
  FreeForm = 'FREE_FORM'
}

export type OperationFilter = {
  excludedTypes?: InputMaybe<Array<SivOperationType>>;
  includedTypes?: InputMaybe<Array<SivOperationType>>;
};

export type OperationPaymentInput = {
  /** Value of the the discount or surcharge. */
  discountOrSurchargeValue?: InputMaybe<Scalars['Float']['input']>;
  /** Type of the discount or surcharge value. */
  discountOrSurchargeValueType?: InputMaybe<DiscountOrSurchargeValueType>;
  /** Is total price flat */
  flat: Scalars['Boolean']['input'];
  /** Total price of the operation. */
  totalPrice?: InputMaybe<Scalars['Float']['input']>;
};

/** Unit for the catalogue operation. */
export enum OperationUnit {
  CubicMeter = 'CUBIC_METER',
  Day = 'DAY',
  FlatRate = 'FLAT_RATE',
  Hour = 'HOUR',
  Kilogram = 'KILOGRAM',
  Liter = 'LITER',
  Meter = 'METER',
  Quantity = 'QUANTITY',
  SquareMeter = 'SQUARE_METER'
}

export type OvertimeEmployeeSaveInput = {
  extraHours?: InputMaybe<Scalars['Float']['input']>;
  fullName: Scalars['String']['input'];
  hours: Scalars['Float']['input'];
  machinesHours?: InputMaybe<Scalars['Float']['input']>;
  materialSurcharge?: InputMaybe<Scalars['String']['input']>;
  role: WorkerRole;
};

export type OvertimeImageInput = {
  imageId: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type OvertimeSaveInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  documentId: Scalars['ID']['input'];
  draftProjectNumber?: InputMaybe<Scalars['String']['input']>;
  employees: Array<OvertimeEmployeeSaveInput>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  images: Array<OvertimeImageInput>;
  orderDate: Scalars['Date']['input'];
  workDescription: Scalars['String']['input'];
  workName: Scalars['String']['input'];
};

export enum OvertimeStatus {
  Archived = 'ARCHIVED',
  ManuallySigned = 'MANUALLY_SIGNED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export type PspdfKitFormField = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ProjectDateUpdateInput = {
  date?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
};

export enum ProjectExternalStatus {
  ActiveConstruction = 'ACTIVE_CONSTRUCTION',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Created = 'CREATED',
  Offered = 'OFFERED',
  Open = 'OPEN',
  Ordered = 'ORDERED',
  OrderNotReceived = 'ORDER_NOT_RECEIVED',
  Rejected = 'REJECTED',
  TerminatedWithoutInvoice = 'TERMINATED_WITHOUT_INVOICE'
}

export enum ProjectImportSourceSystem {
  Dds = 'DDS',
  Itworun = 'ITWORUN',
  Vendoc = 'VENDOC'
}

export type ProjectSorting = {
  direction: SortingDirection;
  field: ProjectSortingField;
};

export enum ProjectSortingField {
  HsProjectNumber = 'HS_PROJECT_NUMBER',
  Name = 'NAME',
  ProfitCenter = 'PROFIT_CENTER',
  Status = 'STATUS',
  UpdatedAt = 'UPDATED_AT'
}

export enum ProjectStatus {
  ActiveConstruction = 'ACTIVE_CONSTRUCTION',
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Offered = 'OFFERED',
  Ordered = 'ORDERED',
  Registered = 'REGISTERED'
}

export type QsstForExternalInput = {
  externalAuthToken?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type QsstLogDownloadInput = {
  externalAuthToken?: InputMaybe<Scalars['String']['input']>;
  /** documentId */
  id: Scalars['ID']['input'];
};

export type QsstSaveInput = {
  clientName: Scalars['String']['input'];
  contractDate?: InputMaybe<Scalars['Date']['input']>;
  /** documentId */
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  onsideEmployee: QsstSaveInputOnsideEmployee;
};

export type QsstSaveInputClientContact = {
  city?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  streetName?: InputMaybe<Scalars['String']['input']>;
  streetNo?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type QsstSaveInputOnsideEmployee = {
  email?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  phoneNo?: InputMaybe<Scalars['String']['input']>;
};

export type QsstSaveInputSpecificScore = {
  cleanliness?: InputMaybe<Scalars['Int']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  service?: InputMaybe<Scalars['Int']['input']>;
  timeliness?: InputMaybe<Scalars['Int']['input']>;
};

export type QsstSendMailInput = {
  attachDocument?: InputMaybe<Scalars['Boolean']['input']>;
  body: Scalars['String']['input'];
  /** Carbon copies of the mail */
  ccs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** documentId */
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type QsstSignInput = {
  clientComments: Scalars['String']['input'];
  clientSignatureFullName: Scalars['String']['input'];
  clientSignatureUpload: Scalars['Upload']['input'];
  externalAuthToken?: InputMaybe<Scalars['String']['input']>;
  /** documentId */
  id: Scalars['ID']['input'];
  recommendationScore: Scalars['Int']['input'];
  specificScore: QsstSaveInputSpecificScore;
};

export enum QsstStatus {
  Archived = 'ARCHIVED',
  ManuallySigned = 'MANUALLY_SIGNED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export type QsstUploadPdfInput = {
  /** documentId */
  id: Scalars['ID']['input'];
  pdf: Scalars['Upload']['input'];
};

export type QualitySaveInput = {
  /** Description of quality */
  description: Scalars['String']['input'];
  /** Name of quality */
  name: Scalars['String']['input'];
};

export type RemoveCatalogueInput = {
  /** Id of the catalogue. */
  id: Scalars['ID']['input'];
};

export type RemoveFolderInput = {
  /** Id of the folder. */
  id: Scalars['ID']['input'];
};

export type RemoveFolderOperationInput = {
  /** Id of the folder. */
  id: Scalars['ID']['input'];
};

export type RemoveManuallyUploadedAcceptanceReportInput = {
  acceptanceReportId: Scalars['ID']['input'];
};

export type RemoveManuallyUploadedOvertimeInput = {
  overtime: Scalars['ID']['input'];
};

export type RetrieveTokensInput = {
  refreshToken: Scalars['String']['input'];
};

export type RiskAssessmentInChargeSaveInput = {
  fullName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role: InChargeRole;
};

export type RiskAssessmentSaveInput = {
  clientName?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  description: Scalars['String']['input'];
  documentId: Scalars['ID']['input'];
  draftProjectNumber?: InputMaybe<Scalars['String']['input']>;
  equipmentIds: Array<Scalars['String']['input']>;
  inCharges: Array<RiskAssessmentInChargeSaveInput>;
  selectedPrecautions: Array<Scalars['String']['input']>;
  specialPrecautions: Array<RiskAssessmentSpecialPrecautionSaveInput>;
};

export enum RiskAssessmentSignatureType {
  Employee = 'EMPLOYEE',
  Manager = 'MANAGER'
}

export type RiskAssessmentSpecialPrecautionSaveInput = {
  precautionCategoryId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export enum RiskAssessmentStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export type SivAdditionalOperationCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  sivId: Scalars['ID']['input'];
  unit?: InputMaybe<SivOperationUnit>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SivBillingAttachmentEmailSendInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  documentId: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SivBillingAttachmentOperationInput = {
  id: Scalars['ID']['input'];
  recordedMeasurement: Scalars['Float']['input'];
};

export type SivBillingAttachmentSaveInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  documentId: Scalars['ID']['input'];
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  operations: Array<SivBillingAttachmentOperationInput>;
};

export enum SivBillingAttachmentStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN',
  Sent = 'SENT'
}

export type SivCockpitDisplayPreferencesUpdateInput = {
  collapsed?: InputMaybe<Scalars['Boolean']['input']>;
  sivId: Scalars['ID']['input'];
  unit?: InputMaybe<SivCockpitDisplayUnit>;
};

export enum SivCockpitDisplayUnit {
  Eur = 'EUR',
  Percentage = 'PERCENTAGE'
}

export type SivCollapseToggleInput = {
  sivId: Scalars['ID']['input'];
};

export type SivDiscountToggleInput = {
  sivId: Scalars['ID']['input'];
};

export type SivDiscountUpdateInput = {
  discount?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export enum SivEmployeeExperienceLevel {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3'
}

export type SivEmployeeProductivityToggleInput = {
  sivId: Scalars['ID']['input'];
};

export enum SivExternalOfferGroupDiffType {
  Added = 'ADDED',
  Deleted = 'DELETED',
  Updated = 'UPDATED'
}

export enum SivExternalOfferGroupField {
  Name = 'NAME',
  No = 'NO'
}

export enum SivExternalOfferOperationDiffType {
  Added = 'ADDED',
  Deleted = 'DELETED',
  Updated = 'UPDATED'
}

export enum SivExternalOfferOperationField {
  ChargePerMinute = 'CHARGE_PER_MINUTE',
  Description = 'DESCRIPTION',
  Material = 'MATERIAL',
  Name = 'NAME',
  No = 'NO',
  PerformanceValue = 'PERFORMANCE_VALUE',
  PlanningTotalMeasurement = 'PLANNING_TOTAL_MEASUREMENT',
  UnitPrice = 'UNIT_PRICE'
}

export type SivFreelanceEmployeeInput = {
  name: Scalars['String']['input'];
};

export type SivGroupCreateInput = {
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  parentGroupId?: InputMaybe<Scalars['ID']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivGroupDuplicateInput = {
  groupIds: Array<Scalars['ID']['input']>;
};

export type SivGroupMoveInput = {
  groupIds: Array<Scalars['ID']['input']>;
  targetGroupId: Scalars['ID']['input'];
};

export type SivGroupRemoveInput = {
  groupIds: Array<Scalars['ID']['input']>;
};

export type SivGroupSetIsCollapsedInput = {
  groupId: Scalars['ID']['input'];
  isCollapsed: Scalars['Boolean']['input'];
};

export type SivGroupSortIndexUpdateInput = {
  groupIds: Array<Scalars['ID']['input']>;
  targetSortIndex: Scalars['Int']['input'];
};

export type SivGroupTransferRecordingValuesInput = {
  groupId: Scalars['ID']['input'];
};

export enum SivGroupType {
  Additional = 'ADDITIONAL',
  Regular = 'REGULAR'
}

export type SivGroupUpdateInput = {
  groupId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type SivImportFromExternalOfferInput = {
  externalOfferId: Scalars['ID']['input'];
  ignorePerformanceValueAndChargePerMinute: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
};

export type SivImportFromOfferInput = {
  offerId: Scalars['ID']['input'];
};

export type SivKeyDataSapTransmitInput = {
  projectIds: Array<Scalars['ID']['input']>;
  sapPassword: Scalars['String']['input'];
  sapUsername: Scalars['String']['input'];
};

export enum SivKeyDataSapTransmitStatus {
  Failed = 'FAILED',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  PartialSuccess = 'PARTIAL_SUCCESS',
  Success = 'SUCCESS'
}

export type SivlvCopyInput = {
  fromProjectId: Scalars['ID']['input'];
  toProjectId: Scalars['ID']['input'];
};

export type SivOperationConvertToRegularInput = {
  operationId: Scalars['ID']['input'];
};

export type SivOperationCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  measurements: Array<SivOperationMeasurementInput>;
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  totalMeasurementMeta?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<SivOperationUnit>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SivOperationDeleteInput = {
  id: Scalars['ID']['input'];
};

export type SivOperationDetachInput = {
  operationId: Scalars['ID']['input'];
};

export type SivOperationDuplicateInput = {
  operationIds: Array<Scalars['ID']['input']>;
};

export type SivOperationMeasurementInput = {
  meta: Array<Scalars['String']['input']>;
  multiplier: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subtraction: Scalars['Boolean']['input'];
  totalMeta?: InputMaybe<Scalars['String']['input']>;
};

export type SivOperationMoveInput = {
  operationIds: Array<Scalars['ID']['input']>;
  targetGroupId: Scalars['ID']['input'];
};

export type SivOperationPerformanceRecordingBatchUpdateHourInput = {
  hour?: InputMaybe<Scalars['Float']['input']>;
  /** Please use commas for decimals. */
  hourMeta?: InputMaybe<Scalars['String']['input']>;
};

export type SivOperationPerformanceRecordingBatchUpdateInput = {
  hour?: InputMaybe<SivOperationPerformanceRecordingBatchUpdateHourInput>;
  operationId: Scalars['ID']['input'];
  totalMeasurement?: InputMaybe<SivOperationPerformanceRecordingBatchUpdateTotalMeasurementInput>;
};

export type SivOperationPerformanceRecordingBatchUpdateTotalMeasurementInput = {
  totalMeasurement?: InputMaybe<Scalars['Float']['input']>;
  /** Please use commas for decimals. */
  totalMeasurementMeta?: InputMaybe<Scalars['String']['input']>;
};

export type SivOperationPinToggleInput = {
  operationId: Scalars['ID']['input'];
};

export type SivOperationPlanningChargePerMinuteUpdateInput = {
  chargePerMinute?: InputMaybe<Scalars['Float']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SivOperationPlanningHourUpdateInput = {
  hour?: InputMaybe<Scalars['Float']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SivOperationPlanningMaterialUpdateInput = {
  material?: InputMaybe<Scalars['Float']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SivOperationPlanningPerformanceValueUpdateInput = {
  operationId: Scalars['ID']['input'];
  performanceValue?: InputMaybe<Scalars['Float']['input']>;
};

export type SivOperationRecordingHourUpdateInput = {
  hour?: InputMaybe<Scalars['Float']['input']>;
  /** Please use commas for decimals. */
  hourMeta?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SivOperationRecordingMaterialUpdateInput = {
  material?: InputMaybe<Scalars['Float']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SivOperationRecordingTotalMeasurementUpdateInput = {
  operationId: Scalars['ID']['input'];
  totalMeasurement?: InputMaybe<Scalars['Float']['input']>;
  /** Please use commas for decimals. */
  totalMeasurementMeta?: InputMaybe<Scalars['String']['input']>;
};

export type SivOperationRemoveInput = {
  operationIds: Array<Scalars['ID']['input']>;
};

export type SivOperationSortIndexUpdateInput = {
  operationIds: Array<Scalars['ID']['input']>;
  targetSortIndex: Scalars['Int']['input'];
};

export type SivOperationSplitInsertInput = {
  chargePerMinute?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  totalMeasurement?: InputMaybe<Scalars['Float']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export enum SivOperationSplitPreference {
  Custom = 'CUSTOM',
  Template = 'TEMPLATE'
}

export type SivOperationSplitPreferenceUpdateInput = {
  operationSplitPreference: SivOperationSplitPreference;
  sivId: Scalars['ID']['input'];
};

export type SivOperationSplitUpdateInput = {
  chargePerMinute?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  no?: InputMaybe<Scalars['String']['input']>;
  totalMeasurement?: InputMaybe<Scalars['Float']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SivOperationSplitsInput = {
  deletes: Array<SivOperationDeleteInput>;
  inserts: Array<SivOperationSplitInsertInput>;
  restored: Scalars['Boolean']['input'];
  saveAsTemplate: Scalars['Boolean']['input'];
  templateName?: InputMaybe<Scalars['String']['input']>;
  updates: Array<SivOperationSplitUpdateInput>;
};

export enum SivOperationType {
  Additional = 'ADDITIONAL',
  Alternative = 'ALTERNATIVE',
  Main = 'MAIN',
  Optional = 'OPTIONAL',
  Regular = 'REGULAR'
}

export enum SivOperationUnit {
  Centimeter = 'CENTIMETER',
  CubicCentimeter = 'CUBIC_CENTIMETER',
  CubicMeter = 'CUBIC_METER',
  Day = 'DAY',
  Decimeter = 'DECIMETER',
  Eur = 'EUR',
  Fixed = 'FIXED',
  FlatRate = 'FLAT_RATE',
  Gram = 'GRAM',
  Hour = 'HOUR',
  Kilogram = 'KILOGRAM',
  Kilometer = 'KILOMETER',
  KilowattHour = 'KILOWATT_HOUR',
  Liter = 'LITER',
  Meter = 'METER',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Quantity = 'QUANTITY',
  SquareCentimeter = 'SQUARE_CENTIMETER',
  SquareDecimeter = 'SQUARE_DECIMETER',
  SquareMeter = 'SQUARE_METER',
  SquareMeterWeek = 'SQUARE_METER_WEEK',
  Ton = 'TON',
  UnitOfAccount = 'UNIT_OF_ACCOUNT',
  Unknown = 'UNKNOWN',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type SivOperationUnitPriceUpdateInput = {
  operationId: Scalars['ID']['input'];
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SivOperationUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  measurements: Array<SivOperationMeasurementInput>;
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['ID']['input'];
  splits: SivOperationSplitsInput;
  totalMeasurementMeta?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<SivOperationUnit>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SivPlanningCostSectionFinishInput = {
  sivId: Scalars['ID']['input'];
};

export type SivPlanningEmployeesSetInput = {
  employeeHSIds: Array<Scalars['ID']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivPlanningFlatRateUpdateInput = {
  flatRate?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivPlanningFreelanceEmployeesSetInput = {
  employees: Array<SivFreelanceEmployeeInput>;
  sivId: Scalars['ID']['input'];
};

export type SivPlanningGroupFlatRateUpdateInput = {
  flatRate?: InputMaybe<Scalars['Float']['input']>;
  groupId: Scalars['ID']['input'];
};

export type SivPlanningSubcontractorCreateInput = {
  cashback?: InputMaybe<Scalars['Float']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  overhead?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
  totalOrderShare: Scalars['Float']['input'];
};

export type SivPlanningSubcontractorRemoveInput = {
  subcontractorId: Scalars['ID']['input'];
};

export type SivPlanningSubcontractorUpdateInput = {
  cashback?: InputMaybe<Scalars['Float']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  overhead?: InputMaybe<Scalars['Float']['input']>;
  subcontractorId: Scalars['ID']['input'];
  totalOrderShare: Scalars['Float']['input'];
};

export type SivPlanningUpdateInput = {
  averageWage?: InputMaybe<Scalars['Float']['input']>;
  hourlyRateTemporaryWorker?: InputMaybe<Scalars['Float']['input']>;
  hourlySurcharge?: InputMaybe<Scalars['Float']['input']>;
  overheadRateTimeWorker?: InputMaybe<Scalars['Float']['input']>;
  overheadRateTrainee?: InputMaybe<Scalars['Float']['input']>;
  overheadRateWage?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivRecordingEmployeesSetInput = {
  employeeHSIds: Array<Scalars['ID']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivRecordingFlatRateUpdateInput = {
  flatRate?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivRecordingFreelanceEmployeesSetInput = {
  employees: Array<SivFreelanceEmployeeInput>;
  sivId: Scalars['ID']['input'];
};

export type SivRecordingGroupFlatRateUpdateInput = {
  flatRate?: InputMaybe<Scalars['Float']['input']>;
  groupId: Scalars['ID']['input'];
};

export type SivRecordingSubcontractorInvoiceInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  surchargeRate?: InputMaybe<Scalars['Float']['input']>;
};

export type SivRecordingSubcontractorInvoicesSetInput = {
  invoices: Array<SivRecordingSubcontractorInvoiceInput>;
  subcontractorId: Scalars['ID']['input'];
};

export type SivRecordingUpdateInput = {
  averageWage?: InputMaybe<Scalars['Float']['input']>;
  hourlyRateTemporaryWorker?: InputMaybe<Scalars['Float']['input']>;
  hourlySurcharge?: InputMaybe<Scalars['Float']['input']>;
  overheadRateTimeWorker?: InputMaybe<Scalars['Float']['input']>;
  overheadRateTrainee?: InputMaybe<Scalars['Float']['input']>;
  overheadRateWage?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SaveAnnotationsInput = {
  documentId: Scalars['ID']['input'];
};

export type SendAcceptanceReportEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SendAcceptanceReportExternalLinkEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SendConstructionNoteEmailInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the construction note. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type SendOfferEmailInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the offer. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type SendOvertimeEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SendOvertimeExternalLinkEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SendRiskAssessmentEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export enum SignatureType {
  Customer = 'CUSTOMER',
  HeinrichSchmid = 'HEINRICH_SCHMID'
}

export enum SortingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SyncProjectInput = {
  id: Scalars['ID']['input'];
};

export type TimeTrackingAdditionalRowAddInput = {
  recordId: Scalars['ID']['input'];
};

export enum TimeTrackingApproveType {
  CommercialApprove = 'COMMERCIAL_APPROVE',
  TechnicalApprove = 'TECHNICAL_APPROVE'
}

export type TimeTrackingBatchDeleteOperationInput = {
  entryId: Scalars['ID']['input'];
  lastUpdatedAt: Scalars['Date']['input'];
  /** This id can be anything. It's not related with SIV or something. It will be returned back in the response to match corresponding operation/action on client side. */
  operationId: Scalars['ID']['input'];
};

export type TimeTrackingBatchEmployeeInput = {
  employeeType: TimeTrackingBatchEmployeeType;
  /** It must be provided if the type is NOT_FROM_SAP and TEMPORARY_WORKER */
  name?: InputMaybe<Scalars['String']['input']>;
  /** It must be provided if the type is SAP */
  sapId?: InputMaybe<Scalars['String']['input']>;
};

export enum TimeTrackingBatchEmployeeType {
  NotFromSap = 'NOT_FROM_SAP',
  Sap = 'SAP',
  TemporaryWorker = 'TEMPORARY_WORKER'
}

export type TimeTrackingBatchInsertOperationInput = {
  break?: InputMaybe<Scalars['Int']['input']>;
  date: Scalars['Date']['input'];
  employee: TimeTrackingBatchEmployeeInput;
  endTime?: InputMaybe<Scalars['Int']['input']>;
  indicator?: InputMaybe<TimeTrackingIndicator>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** This id can be anything. It's not related with SIV or something. It will be returned back in the response to match corresponding operation/action on client side. */
  operationId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['Int']['input']>;
};

export type TimeTrackingBatchOperationInput = {
  deletes?: InputMaybe<Array<TimeTrackingBatchDeleteOperationInput>>;
  inserts?: InputMaybe<Array<TimeTrackingBatchInsertOperationInput>>;
  updates?: InputMaybe<Array<TimeTrackingBatchUpdateOperationInput>>;
};

export enum TimeTrackingBatchOperationType {
  Delete = 'DELETE',
  Insert = 'INSERT',
  Update = 'UPDATE'
}

export type TimeTrackingBatchUpdateOperationInput = {
  break?: InputMaybe<Scalars['Int']['input']>;
  endTime?: InputMaybe<Scalars['Int']['input']>;
  entryId: Scalars['ID']['input'];
  indicator?: InputMaybe<TimeTrackingIndicator>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** This id can be anything. It's not related with SIV or something. It will be returned back in the response to match corresponding operation/action on client side. */
  operationId: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['Int']['input']>;
};

export enum TimeTrackingEntryField {
  Break = 'BREAK',
  EndTime = 'END_TIME',
  Indicator = 'INDICATOR',
  StartTime = 'START_TIME'
}

export type TimeTrackingEntryIndicatorSaveInput = {
  date: Scalars['Date']['input'];
  /** Send as null to remove an existing indicator */
  indicator?: InputMaybe<TimeTrackingIndicator>;
  rowId: Scalars['ID']['input'];
};

export type TimeTrackingEntryMultiRemoveInput = {
  entryIds: Array<Scalars['ID']['input']>;
};

export type TimeTrackingEntryNoteSaveInput = {
  /** Send as null to remove an existing note */
  content?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  rowId: Scalars['ID']['input'];
};

export enum TimeTrackingEntryTimeField {
  Break = 'BREAK',
  EndTime = 'END_TIME',
  StartTime = 'START_TIME'
}

export type TimeTrackingEntryTimeMultiRemoveInput = {
  entryIds: Array<Scalars['ID']['input']>;
  field: TimeTrackingEntryTimeField;
};

export type TimeTrackingEntryTimeRemoveInput = {
  entryId: Scalars['ID']['input'];
  field: TimeTrackingEntryTimeField;
};

export type TimeTrackingEntryTimeSaveInput = {
  date: Scalars['Date']['input'];
  field: TimeTrackingEntryTimeField;
  rowId: Scalars['ID']['input'];
  value: Scalars['Int']['input'];
};

export enum TimeTrackingIndicator {
  A = 'A',
  A0 = 'A0',
  A1 = 'A1',
  A2 = 'A2',
  B = 'B',
  E = 'E',
  E5 = 'E5',
  E6 = 'E6',
  F = 'F',
  K = 'K',
  K2 = 'K2',
  K6 = 'K6',
  K9 = 'K9',
  O = 'O',
  O1 = 'O1',
  O3 = 'O3',
  O4 = 'O4',
  O5 = 'O5',
  O6 = 'O6',
  O7 = 'O7',
  P = 'P',
  Rb = 'RB',
  Rc = 'RC',
  Rd = 'RD',
  Re = 'RE',
  Rf = 'RF',
  Rg = 'RG',
  Rk = 'RK',
  Rl = 'RL',
  Rm = 'RM',
  Rn = 'RN',
  Ro = 'RO',
  Rp = 'RP',
  S = 'S',
  S1 = 'S1',
  S2 = 'S2',
  T = 'T',
  T8 = 'T8',
  T9 = 'T9',
  U = 'U',
  U1 = 'U1',
  U2 = 'U2',
  U3 = 'U3',
  U6 = 'U6',
  U7 = 'U7',
  V = 'V',
  V5 = 'V5',
  V9 = 'V9',
  W = 'W'
}

export type TimeTrackingProjectMultiApproveInput = {
  approveType: TimeTrackingApproveType;
  projectIds: Array<Scalars['ID']['input']>;
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingRemoveWeekYearProjectInput = {
  projectId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingResponsibleToggleInput = {
  weekRecordId: Scalars['ID']['input'];
};

export type TimeTrackingRowRemoveInput = {
  rowId: Scalars['ID']['input'];
};

export type TimeTrackingSapEmployeeWeekRecordAddInput = {
  projectId: Scalars['ID']['input'];
  sapEmployeeId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingSapTransmitInput = {
  entryIds: Array<Scalars['ID']['input']>;
  sapPassword: Scalars['String']['input'];
  sapUsername: Scalars['String']['input'];
};

export enum TimeTrackingSortingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type TimeTrackingSwitchMultiSapEmployeeInput = {
  currentEmployeeId: Scalars['ID']['input'];
  newEmployeeId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingSwitchProjectInput = {
  currentProjectId: Scalars['ID']['input'];
  newProjectId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingSwitchSapEmployeeInput = {
  recordId: Scalars['ID']['input'];
  sapEmployeeId: Scalars['ID']['input'];
};

export enum TimeTrackingUnknownEmployeeType {
  NotFromSap = 'NOT_FROM_SAP',
  TemporaryWorker = 'TEMPORARY_WORKER'
}

export type TimeTrackingUnknownEmployeeUpdateInput = {
  name: Scalars['String']['input'];
  recordId: Scalars['ID']['input'];
};

export type TimeTrackingUnknownEmployeeWeekRecordAddInput = {
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  type: TimeTrackingUnknownEmployeeType;
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingWeekRangeInput = {
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingWeekRecordSwitchProjectInput = {
  newProjectId: Scalars['ID']['input'];
  weekRecordId: Scalars['ID']['input'];
};

export type TimesheetProjectSorting = {
  direction: TimeTrackingSortingDirection;
  type: TimesheetProjectSortingType;
};

export enum TimesheetProjectSortingType {
  HsProjectNumber = 'HS_PROJECT_NUMBER',
  Name = 'NAME'
}

export type ToggleShowSubProjectHoursInput = {
  id: Scalars['ID']['input'];
};

export type UpdateCatalogueInput = {
  /** ID of the catalogue. */
  catalogue: Scalars['String']['input'];
  /** Catalogue name. */
  name: Scalars['String']['input'];
};

export type UpdateFolderForFrameworkInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Category of the offer. */
  category: Scalars['ID']['input'];
  /** Id of the offer. */
  folder: Scalars['ID']['input'];
  /** Measurements of folder */
  measurements: Array<UpdateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type UpdateFolderForFreeFormInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the offer. */
  folder: Scalars['ID']['input'];
  /** Measurements of folder */
  measurements: Array<UpdateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type UpdateFolderOperationInput = {
  /** Is this operation's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Measurements of operation */
  measurements: Array<UpdateMeasurementInput>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Note for the operation */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Id of the operation. */
  operation: Scalars['ID']['input'];
  /** Is this operation optional */
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  /** Operation payment input. Please send totalPrice flat is true, otherwise please send discount/surcharge value and type. If you do not have any price adjustment please do not send this input at all. */
  payment?: InputMaybe<OperationPaymentInput>;
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export type UpdateMeasurementInput = {
  /** ID of the measurement. */
  id?: InputMaybe<Scalars['String']['input']>;
  meta: Array<Scalars['String']['input']>;
  multiplier?: InputMaybe<Scalars['Float']['input']>;
  /** Offer name. */
  name: Scalars['String']['input'];
  /** Is subtraction enabled */
  subtraction: Scalars['Boolean']['input'];
  /** Total measurement */
  total: Scalars['Float']['input'];
  /** Total measurement meta for the operation. */
  totalMeta: Scalars['String']['input'];
};

export type UpdateOfferCalculationInput = {
  /** Extra prices of the offer. */
  extraPrices: Array<ExtraPriceInput>;
  /** ID of the offer. */
  offer: Scalars['ID']['input'];
  /** Tax rate of the offer. */
  taxRate: Scalars['Float']['input'];
};

export type UpdateOfferSortingForFrameworkInput = {
  /** Id of the item needs to be updated */
  id: Scalars['String']['input'];
  /** Sort of the item */
  sort: Scalars['Int']['input'];
  /** Type of the sorted items. */
  type: OfferSortableType;
};

export type UpdateOfferSortingForFreeFormInput = {
  /** Drop destination of item */
  destination?: InputMaybe<Scalars['String']['input']>;
  /** Id of the item needs to be updated */
  id: Scalars['String']['input'];
  /** Sort of the item */
  sort: Scalars['Int']['input'];
  /** Type of the sorted item. */
  type: OfferSortableType;
};

export type UpdateOperationInput = {
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Id of the operation. */
  operation: Scalars['ID']['input'];
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export type UpdateProjectStatusInput = {
  project: Scalars['ID']['input'];
  status: ProjectStatus;
};

export type UpdateTaxRateInput = {
  /** ID of the offer. */
  offer: Scalars['String']['input'];
  /** TaxRate for the offer. */
  taxRate: Scalars['Float']['input'];
};

export type UploadAcceptanceReportSignatureInput = {
  acceptanceReport: Scalars['ID']['input'];
  fullName: Scalars['String']['input'];
  type: AcceptanceReportSignatureType;
};

export type UploadManualPdfToAcceptanceReportInput = {
  acceptanceReport: Scalars['ID']['input'];
};

export type UploadManualPdfToOvertimeInput = {
  overtime: Scalars['ID']['input'];
};

export type UploadOvertimeSignatureInput = {
  fullName: Scalars['String']['input'];
  overtime: Scalars['ID']['input'];
  type: SignatureType;
};

export type UploadRiskAssessmentSignatureInput = {
  fullName: Scalars['String']['input'];
  riskAssessment: Scalars['ID']['input'];
  type: RiskAssessmentSignatureType;
};

export type ViewerDrivePreferencesInput = {
  viewPreferences: DriveViewPreferencesInput;
};

export type ViewerLanguageUpdateInput = {
  code: LanguageCode;
};

export type ViewerProjectDrivePreferenceInput = {
  entrySorting?: InputMaybe<DriveEntrySortingInput>;
  folderSorting?: InputMaybe<DriveFolderSortingInput>;
  projectId: Scalars['ID']['input'];
  selectedFolderId?: InputMaybe<Scalars['ID']['input']>;
};

export type WatchEventsInput = {
  events: Array<Event>;
};

export enum WorkerRole {
  Fa = 'FA',
  Helper = 'HELPER',
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  Master = 'MASTER',
  Va = 'VA'
}

export type RetrieveTokenMutationVariables = Exact<{
  input: RetrieveTokensInput;
}>;


export type RetrieveTokenMutation = { __typename?: 'Mutation', retrieveTokens: { __typename?: 'RetrieveTokensResult', refreshToken: string, accessToken: string } };

export type AllProjectsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AllProjectsQuery = { __typename?: 'Query', projects: { __typename?: 'Projects', projects: Array<{ __typename?: 'Project', id: string, city?: string | null, street?: string | null, postCode?: string | null, addressLine?: string | null }> } };


export const RetrieveTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RetrieveToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RetrieveTokensInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"retrieveTokens"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}},{"kind":"Field","name":{"kind":"Name","value":"accessToken"}}]}}]}}]} as unknown as DocumentNode<RetrieveTokenMutation, RetrieveTokenMutationVariables>;
export const AllProjectsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"allProjects"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"page"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"projects"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"page"},"value":{"kind":"Variable","name":{"kind":"Name","value":"page"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"projects"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"street"}},{"kind":"Field","name":{"kind":"Name","value":"postCode"}},{"kind":"Field","name":{"kind":"Name","value":"addressLine"}}]}}]}}]}}]} as unknown as DocumentNode<AllProjectsQuery, AllProjectsQueryVariables>;